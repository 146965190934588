@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

:root {
    --primary-font: 'Plus Jakarta Sans', sans-serif;
    --secondary-font: 'Open Sans', sans-serif;
    --primary-color: #01479F;
    --secondary-color: #18479f;
    --dark-color: #000000;
    --info-color: #073da1;
    --success-color: #189d0e;
    --warning-color: #123123;
    --danger-color: #e20000;
    --light-color: #bbb;
    --white-color: #fff;
    --white-secondory-color: #f5f5f5;
    --hover-color: rgba(0, 19, 145, 0.2);
    --shadow-color: rgba(0, 0, 0, 0.1);
    --app-background-color: #F4F7FA;
    --gray-color: gray;
    --hover-primary-color: rgba(0, 19, 145, 0.5);
    --general-transition: all .4s ease;
}

html {
    font-family: var(--primary-font), serif;
    color: var(--primary-color);
    scroll-behavior: smooth;
    overflow-x: hidden;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

a:hover {
    color: var(--secondary-color);
}

body {
    overflow-x: hidden;
    background-color: #fff;
}



.container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
}

.p-0 {
    padding: 0;
}

.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 2rem;
}

.p-3 {
    padding: 3rem;
}

.p-10 {
    padding: 10px
}

.p-20 {
    padding: 20px
}

.p-30 {
    padding: 30px;
}

.pl-10 {
    padding-left: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pr-1{
    padding-right: 1rem;
}

.pr-2{
    padding-right: 2rem;
}

.pr-3{
    padding-right: 3rem;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 1rem;
}

.m-2 {
    margin: 2rem;
}

.m-3 {
    margin: 3rem;
}

.w-100 {
    width: 100%;
}

.mt-0-5 {
    margin-top: 0.5rem
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.w-75 {
    width: 75%;
}

.w-33 {
    width: 33%;
}

.w-66 {
    width: 66%;
}

.w-20 {
    width: 20%;
}

.w-80 {
    width: 80%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-30 {
    width: 30%;
}

.w-10 {
    width: 10%;
}

.w-90 {
    width: 90%;
}

.h-100 {
    height: 100%;
}

.h-50 {
    height: 50%;
}

.h-25 {
    height: 25%;
}

.h-75 {
    height: 75%;
}

.h-33 {
    height: 33%;
}

.h-66 {
    height: 66%;
}

.h-20 {
    height: 20%;
}


.text-align-center {
    text-align: center;
}

.text-align-right{
    text-align: right;
}

.font-weight-bold {
    font-weight: bold;
}

.box-shadow {
    box-shadow: 0px 0px 5px 5px var(--shadow-color);
}

.justify-content-center {
    justify-content: center;
}

.text-align-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.h-100{
    height: 100%;
}

.header{
    background-color: var(--white-color);
    color:var(--white-color);
}

.header-top{
    display: flex;
    position: relative;
    background-color: var(--primary-color);
    justify-content: space-between;

    .container
    {
        display: flex;
        justify-content: space-between;
    }
}

.header-top-menu{
    padding:.5rem;
}

.header-content{
    position: relative;
    padding-top: .75rem;
    padding-bottom: .75rem;
    justify-content: center;
    border-bottom-style: solid;
    border-bottom-color: var(--shadow-color);
    border-bottom-width: 1px;

    .container{
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;

    }

    .logo{
        display: block;
        position: relative;

        img{
            width: 240px;
            height: 100%;
        }
    }

    .search{
        display: flex;
        position: relative;
        align-content: center;
        width: 35rem;
        .search-text{
            width: 100%;
            border: 1px solid var(--primary-color);
            align-content: center;
            align-items: center;
            padding: .5rem .5rem .5rem 1rem;

        }

        .search-text:focus{
            outline: none;
            border: 1px solid var(--primary-color);
            border-radius: 0;
            color: var(--primary-color);
        }

        .search-button{
            display: flex;
            justify-content: center;
            margin-left: -.2rem;
            padding: .5rem;
            width: 10rem;
            background-color: var(--primary-color);
            color: var(--white-color);
            align-items: center;
            align-content: center;
            text-align: center;
            cursor: pointer;
            gap: .5rem;
        }

        .search-result-content{
            display: block;
            position: absolute;
            width: 100%;
            top: 38px;
            background-color: var(--white-color);
            z-index: 2;
            padding: .5rem;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            box-shadow: 0px 15px 5px 0px var(--shadow-color);

            ul{
                display: block;
                position: relative;

                li{
                    display: flex;
                    position: relative;
                    justify-content: space-between;
                    padding: .5rem;
                    cursor: pointer;
                    border-bottom: 1px solid var(--shadow-color);
                }
            }
        }
    }



    .user-menu{
        position: relative;
        color: var(--primary-color);
        display: flex;
        gap:1.5rem;
        align-items: center;
        text-align: center;

        .user-menu-item{
            display: block;
            position: relative;

            .user-menu-text{
                font-weight: 500;
                font-size: 15px;
                padding: .25rem;
            }

            .user-menu-icon{
                font-size: 1.5rem;
            }

            .user-menu-badge{
                position: absolute;
                top: -15px;
                right: 5px;
                background-color: red;
                color: white;
                font-weight: bold;
                padding: .35rem;
                border-radius: 50%;
            }

            .user-menu-dropdown{
                display: block;
                position: relative;

                .dropdown-content{
                    display: none;
                    position: absolute;
                    background-color: var(--white-color);
                    z-index: 2;
                    box-shadow: 0px 15px 5px 0px var(--shadow-color);
                    border-radius: 0.5rem;
                    flex-direction: column;
                    padding-top: 1rem;
                    font-weight: bold;
                    width: 200px;
                    margin-left: -135px;


                    .dropdown-item{
                        padding: .75rem;
                        user-select: none;
                        cursor: pointer;
                        border-bottom: 1px solid var(--shadow-color);

                    }

                    .dropdown-item:hover{
                        background-color: var(--hover-color);
                    }
                }


            }
            .user-menu-dropdown:hover .dropdown-content{
                display: flex;
            }
        }


    }
}

.header-category-menu{
    position: relative;
    color: var(--primary-color);
    box-shadow: 0px 5px 5px 0 var(--shadow-color);

    .menu{
        display:flex;
        gap:1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: 400;
        font-size: .8rem;

        .menu-item{
            font-weight: 600;
            font-size: .85rem;
            user-select: none;
            cursor: pointer;
        }

        .menu-item:hover{
            color: var(--hover-color);
        }
    }

    .dropdown{
        display: block;
        position: relative;

        .dropdown-content{
            display: none;
            position: absolute;
            background-color: white;
            min-width: 200px;
            z-index: 2;
            box-shadow: 0px 15px 5px 0px var(--shadow-color);
            border-radius: .5rem;
            flex-direction: column;
            padding-top: 1rem;
            font-weight: bold;


            .dropdown-item{
                padding: .75rem;
                user-select: none;
                cursor: pointer;
                border-bottom: 1px solid var(--shadow-color);

            }

        }

    }
    .dropdown:hover .dropdown-content{
        display: flex;
    }
}

.app{
    background-color: var(--app-background-color);
}

.app-container{
    display: block;
    position: relative;
    //min-height: 80vh;
}

.section-slider{
    display: block;
    position: relative;

    .slider-content{
        display: block;
        position: relative;
        margin-top: 30px;

        .container{
            display:grid;
            position: relative;
            grid-template-columns: 2fr 1fr ;
            gap: 10px;
            width: 100%;

            .slider{
                display: block;
                position: relative;
                background-color: var(--white-color);
                height: 100%;
                width: 100%;

                img{
                    width: 100%;
                    height:100%;
                    border-radius: .25rem;
                }
            }

            .fast{
                display: block;
                position: relative;
                background-color: var(--white-color);
                height: 100%;
                width: 100%;

                img{
                    width: 100%;
                    height: 100%;
                    border-radius: .25rem;
                }
            }
        }
    }

}

.card-list{
    display: block;
    position: relative;

    .card-list-label{
        display:flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .label{
            font-weight: 600;
            font-size: 1.5rem;
            color: var(--primary-color);
        }

        .more{
            font-weight: 500;
            font-size: 1rem;
            color: var(--primary-color);
        }
    }

    .card-content{
        display: block;
        position: relative;
    }

    .mySwiper{
        display: block;
        padding: .25rem;
    }

    .card-item{
        background-color: var(--white-color);
        border-radius: .25rem;

        .card-item-content{
            display: block;
            position: relative;
            width: 100%;
            height: 100%;

            img{
                width: 100%;
                height: 250px;
                object-fit: cover;
                border-top-left-radius: .25rem;
                border-top-right-radius: .25rem;
            }


            .product-content{
                display: block;
                position: relative;
                padding-top: .5rem;
                padding-left: .75rem;
                padding-right: .75rem;
                padding-bottom: .75rem;

                .product-title{
                    font-weight: 500;
                    font-size: 14px;
                    color: gray;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    position: relative;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow:hidden;
                }

                .product-info{
                    display: flex;
                    position: relative;
                    justify-content: space-between;
                    margin-top: .5rem;
                    font-weight: bold;
                    color: var(--primary-color);
                    align-items: center;

                    .product-price{
                        font-size: 1.2rem;
                    }

                    .product-add-basket{
                        font-size: 12px;
                        display: flex;
                        position: relative;
                        align-items: center;
                        justify-content: center;
                        padding: .5rem;
                        background-color: var(--primary-color);
                        color: white;
                        cursor: pointer;
                        border-radius: .25rem;
                        gap: .5rem;
                    }

                    .product-add-basket:hover{
                        background-color: var(--secondary-color);
                    }

                    .product-add-basket:active{
                        background-color: var(--danger-color);
                    }
                }
            }

        }
    }

    .card-item:hover{
        box-shadow: 0px 0px 5px 5px rgba(0, 19, 145, 0.2);
    }
}

.footer{
    display: block;
    position: relative;
    background-color: var(--white-color);
    margin-top: 2rem;
    padding-bottom: 5rem;

    .container{
        display: flex;
        position: relative;
        padding-top: 1rem;
        justify-content: space-between;

        .company-info{
            display:block;
            position: relative;

            .company-logo{
                display: block;
                position: relative;

                img{
                    width: 200px;
                    height: 100%;
                }
            }

            .company-contact{
                display:block;
                position: relative;
                margin-top: .5rem;

                .company-phone{
                    display: flex;
                    align-items: center;
                    position: relative;
                    gap: 5px;
                    color: var(--primary-color);
                    font-weight: 500;
                    margin-bottom: .5rem;

                    .icon{
                        font-size: 12px;
                    }
                    .text{
                        font-size: 12px;
                    }
                }
                .company-adress{
                    display: flex;
                    align-items: center;
                    position: relative;
                    gap: 5px;
                    color: var(--primary-color);
                    font-weight: 500;

                    .icon{
                        font-size: 12px;
                    }

                    .text{
                        font-size: 12px;
                    }
                }

            }

        }

        .fast-link{
            display:flex;
            position: relative;
            font-size: 12px;
            color: var(--primary-color);
            margin-left: 1rem;
            gap:100px;

            .fast-link-item{
                display: block;
                position: relative;
                padding-top: .25rem;
                padding-bottom: .25rem;
                font-weight: 500;
                cursor: pointer;
            }
        }


    }
}

.section-products{
    display:flex;
    position:relative;
    gap:1rem;
    margin-top:2rem;
}

.product-list-content{
    display: block;
    position: relative;
    width: 100%;

    .product-list-header{
        display: block;
        position: relative;
        width: 100%;

        .product-list-filter{
            display:flex;
            gap: 1rem;
            position: relative;
            padding-top: .5rem;
            margin-right: 1rem;
            margin-bottom: .5rem;
            align-items: center;
            justify-content: space-between;
        }
    }

    .product-list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;


        .product-card{
            flex: 0 0 calc(25% - 20px); /* Kartların %33'ü genişliğinde olmasını sağlar */
            margin:.5rem;
            background-color: var(--white-color);
            border-radius: .5rem;
            padding: .5rem;


            .product-image{
                width: 100%;
                height: 200px;

                img{
                    border-radius: .5rem;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .product-detail{
                display: block;
                position: relative;
                margin-top: .5rem;
                margin-bottom: .5rem;


                .product-name{
                    display: -webkit-box;
                    position: relative;
                    font-weight: 500;
                    font-size: 18px;
                    color: gray;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow:hidden;

                }

                .product-stock-code{
                    display: block;
                    position: relative;
                    font-weight: 500;
                    font-size: 12px;
                    color: gray;
                }

                .product-price{
                    font-weight: 700;
                    font-size: 1.5rem;
                    color: var(--primary-color);
                }

                .product-button{
                    display:flex;
                    position: relative;
                    background-color: var(--primary-color);
                    color: var(--white-color);
                    justify-content: center;
                    border-radius: .5rem;
                    gap:10px;
                    font-weight: 500;
                    padding:.5rem;
                    margin-top: .5rem;
                    cursor: pointer;
                }

                .product-button:hover{
                    background-color: var(--danger-color);
                }

                .product-button-disabled {
                    display: flex;
                    position: relative;
                    background-color: var(--gray-color);
                    color: var(--white-color);
                    justify-content: center;
                    border-radius: .5rem;
                    gap: 10px;
                    font-weight: 500;
                    padding: .5rem;
                    margin-top: .5rem;
                    cursor: not-allowed;
                }
            }
        }

        .product-card:hover{
            box-shadow: 0 0 5px 5px var(--info-color);
        }
    }

}

.products-filter{
    margin-top: .5rem;
    display: block;
    position: relative;
    min-width: 15rem;
    
    .filter-item{
        display: block;
        position: relative;
        background-color: var(--white-color);
        padding: .5rem;
        border-radius: .5rem;


        .filter-label{
            display: block;
            position: relative;
            font-weight: 500;
            font-size: 1rem;
            color: var(--primary-color);
        }

        .filter-content{
            display: block;
            position: relative;
            padding-top: .5rem;


            .checkbox-item{
                display:block;
                position: relative;
                align-items: center;
                margin-bottom: .5rem;
                cursor: pointer;

                .main-category-name{
                    font-weight: 700;
                    font-size: 12px;
                    color: var(--primary-color);
                    padding: .25rem;
                }

                .main-category-name:hover{
                    color: var(--hover-primary-color);
                }


                label{
                    font-weight: 800;
                    font-size: 10px;
                    color: var(--primary-color);
                    margin-right: 5px;
                }

                input[type="checkbox"]{
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .sub-checkbox-item{
                    margin-left: 10px;
                    display: none;

                    label{
                        font-weight: 500;
                    }

                    .sub-category-name{
                        font-weight: 600;
                        font-size: 12px;
                        color: var(--primary-color);
                        padding: .25rem;
                    }

                    .sub-category-name:hover{
                        color: var(--hover-primary-color);
                    }
                }

                .active{
                    display: block;
                }
            }
        }
    }

    .cat-menu-button-close{
        display: none;
    }

}

.cat-menu-button-open{
    display: none;
}
.product-detail-content{
    display: flex;
    position: relative;
    gap: .5rem;
    background-color: var(--white-color);
    border-radius: .5rem;

    .product-image{
        display: block;
        position: relative;
        width: 50%;
        height: 100%;
        background-color: var(--white-color);
        padding:.5rem;
        border-radius: .5rem;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: .5rem;
        }
    }

    .product-detail{
        display: block;
        position: relative;
        background-color: var(--white-secondory-color);
        width: 50%;
        border-top-right-radius: .5rem;
        border-bottom-right-radius: .5rem;
        color: var(--dark-color);
        padding:1rem;

        .product-name{
            display: block;
            position: relative;
            font-weight: bold;
            font-size: 2.5rem;
            color: var(--primary-color);

        }

        .price{
            display: block;
            position: relative;
            font-weight: bold;
            font-size: 2rem;
            color: var(--primary-color);
        }

        .add-basket{
            display:flex;
            gap: 1rem;
            position:relative;
            background-color: var(--primary-color);
            border-radius: .5rem;
            padding: 1rem;
            width: 300px;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: white;
            cursor: pointer;
            user-select: none;
        }

        .add-basket:hover{
            background-color: var(--secondary-color);
        }

        .add-basket:active{
            background-color: var(--danger-color);
        }

        .icon{
            font-weight: bold;
            font-size: 1.5rem;
        }
        .text{
            font-weight: bold;
            font-size: 1.5rem;
        }

    }
}

.breadcrumb{
    display: flex;
    position: relative;
    width: 100%;


    .bread-item{
        display: flex;
        position: relative;
        font-weight: 500;
        font-size: 1rem;
        color: var(--gray-color);

        a{
            color: var(--gray-color);
        }

        a:hover{
            color: rgba(0, 19, 145, 0.5);
        }

        .seperator{
            display: block;
            position: relative;
            margin: 0 .5rem;
        }

        .active{
            color: var(--primary-color);
        }

        .active:hover{
            color: var(--hover-primary-color);
        }

    }
}

.basket-card{
    display: block;
    position: relative;
    background-color: var(--white-color);
    border-radius: .5rem;
    min-height: 100px;

    .card-title{
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        color: var(--primary-color);
        font-weight: 600;
        font-size: 1.5rem;
        padding: 1rem;
        border-bottom-style: solid;
        border-bottom-color: var(--shadow-color);
        justify-content: space-between;

        .icon{
            font-size: 1.75rem;
        }
    }

    .basket-card-content{
        display: block;
        position: relative;
        padding: .5rem;

        .basket-card-item{
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;
            padding:.5rem;
            border: 1px solid var(--shadow-color);
            border-radius: .5rem;
            margin-bottom: .5rem;

            .basket-card-item-image{
                display: block;
                position: relative;
                height: 100px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: .5rem;
                }
            }

            .basket-card-item-title{
                display: block;
                position: relative;
                font-weight: 500;
                font-size: 1rem;
                margin:.5rem;
            }

            .basket-card-item-price{
                display: block;
                position: relative;
                margin:.5rem;
            }

            .basket-card-item-quantity{
                display: flex;
                gap: .5rem;
                position: relative;
                margin:.5rem;
                justify-items: center;
                justify-content: center;
                align-items: center;

                input[type="number"] {
                    width: 50px;
                    height: 30px;
                    border: 1px solid var(--shadow-color);
                    border-radius: .5rem;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    -webkit-appearance: none;
                    -moz-appearance: textfield;
                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .button{
                    display: flex;
                    position: relative;
                    background-color: var(--primary-color);
                    color: var(--white-color);
                    padding: .75rem;
                    border-radius: .5rem;
                    cursor: pointer;
                    gap: .5rem;
                    user-select: none;
                    align-items: center;
                    justify-content: center;
                }

                .button:hover{
                    background-color: var(--secondary-color);
                }

                .button:active{
                    background-color: var(--danger-color);
                }
            }

            .basket-card-item-price{
                display: block;
                position: relative;
                margin:.5rem;
            }

            .basket-card-item-product-remove{
                display: block;
                position: relative;
                color: var(--primary-color);
                margin:.5rem;
                cursor: pointer;
            }

            .basket-card-item-product-remove:hover{
                color: red;
            }
        }

        .order-item{
            display: flex;
            position: relative;
            justify-content: right;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding: .5rem;

            .label{
                margin-right: 1rem;
                font-size: 1.1rem;
                font-weight: 500;
            }

            .value{
                font-size: 1.2rem;
                font-weight: 800;
                width: 200px;
            }
        }

        .basket-card-empty{
            display: block;
            position: relative;
            padding: 2rem;
            text-align: center;
            font-weight: 500;
            font-size: 1.5rem;
            color: gray;

            .icon{
                font-size: 5rem;
            }

            .title{
                font-size: 1.5rem;
                margin-top: 2rem;
            }
        }

        .order-button{
            display: flex;
            position: relative;
            padding: 1rem;
            justify-content: right;

            .button{
                display:flex;
                position: relative;
                gap: 2rem;
                background-color: var(--primary-color);
                width: 300px;
                justify-content: center;
                color: var(--white-color);
                font-weight: 500;
                font-size: 1.2rem;
                padding: 1rem;
                border-radius: .5rem;
                align-items: center;
                cursor: pointer;

                .icon{
                    font-size: 1.5rem;
                }

                .label{
                    font-weight: 600;
                }
            }

            .button:hover{
                background-color: var(--secondary-color);
            }

            .button:active{
                background-color: var(--danger-color);
            }

        }

        .order-success {
            display: block;
            position: relative;
            padding: 2rem;
            text-align: center;
            font-weight: 500;
            font-size: 1.5rem;
            color: green;

            .icon {
                font-size: 7rem;
            }

            .text {
                font-size: 1.5rem;
                margin-top: 2rem;
                color: var(--primary-color);
            }
        }
    }


}

.basket-modal{
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: block;

    .product-image{
        display: block;
        position: relative;
        object-fit: cover;

        img{
            width: 100%;
            height: 100%;
        }
    }

    .product-detail{
        color: var(--primary-color);

        .product-quantity{
            display: flex;
            position: relative;
            gap: .5rem;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;

            input[type="number"] {
                width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: .5rem;
                text-align: center;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                margin: 0;
                -webkit-appearance: none;
                -moz-appearance: textfield;
                padding: .5rem;
                font-weight: bold;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            .button{
                display: flex;
                position: relative;
                background-color: var(--primary-color);
                color: var(--white-color);
                padding: .75rem;
                border-radius: .5rem;
                cursor: pointer;
                gap: .5rem;
                user-select: none;
                align-items: center;
                justify-content: center;
            }

            .button:hover{
                background-color: var(--secondary-color);
            }

            .button:active{
                background-color: var(--danger-color);
            }




        }

        .add-basket{
            display:flex;
            gap: 1rem;
            position:relative;
            background-color: var(--primary-color);
            border-radius: .5rem;
            padding: .5rem;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: var(--white-color);
            cursor: pointer;
            user-select: none;
            margin-top: 1rem;
        }

        .add-basket:hover{
            background-color: var(--secondary-color);
        }

        .add-basket:active{
            background-color: var(--danger-color);
        }

        .icon{
            font-weight: bold;
            font-size: 1.5rem;
        }

        .text{
            font-weight: bold;
            font-size: 1.5rem;
        }

    }
}


@media only screen and (max-width: 1100px) {
     .header-top{
         display:flex;
         position: relative;

         .container{
             display: flex;
             position: relative;
             text-align: center;
             margin-left:10px;
             margin-right:10px;

             .header-top-menu{
                 padding:2px;
                 font-size: 14px;
             }
         }
     }

    .header-content{
        .container{
            display:block;

            .logo{
                text-align: center;
            }

            .search{
                margin-top: 10px;
                width: 100%;
                padding: 1rem;
            }

            .user-menu{
                text-align: center;
                position: relative;
                width: 100%;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                justify-content: space-evenly;
                margin-top: .5rem;

            }


        }
    }

    .header-category-menu{
        justify-content: center;

        .container{
            .menu{
                justify-content: center;
                gap: 1rem;

                .menu-item{
                    font-size: 11px;

                    .dropdown-content{
                        width: auto;
                    }
                }
            }
        }

        .dropdown:hover .dropdown-content{
            display: none;
        }
    }

    .section-slider{
        .slider-content{
            .container{
                padding: .5rem;
                grid-template-columns: 1fr;

                .fast{
                    display: none;
                }
            }
        }
    }

    .section-new-product{
        margin-left: 10px;
        margin-right: 10px;
    }

    .section-rate-product{
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-item-content{
        .product-content{
            .product-info{
                .product-price{
                    font-size: 14px !important;
                }
            }
        }
    }

    .footer{
        margin-top: 1rem;

        .container{
            display: block;

            .company-info{

                .company-logo{
                    text-align: center;
                }

                .company-contact{
                    text-align: center;

                    .company-phone{
                        justify-content: center;
                    }

                    .company-adress{
                        justify-content: center;
                    }
                }
            }

            .fast-link{
                justify-content: center;
                margin-top: 1rem;
            }

        }
    }

    .section-products{
        display: block;
        position: relative;

        .products-filter{
            margin-right: 0;
            margin-left: 0;
            position: fixed;
            display: none;
            width: 100%;
            top: 0;
            z-index: 99;
            height: 100%;
            overflow: auto;
            border-radius: 0;
            margin-top: 0;



            .filter-item{
                .filter-label{
                    width: 100%;
                    height: 100%;
                    background-color: white;
                    font-size: 1.5rem;
                }
            }

            .cat-menu-button-close{
                display: block;
                position: fixed;
                border-radius: 1rem;
                padding: 2rem;
                top: 0;
                right: 0;
            }


        }
        .active{
            display: block !important;
        }


        .cat-menu-button-open{
            display: block;
            position: fixed;
            right: 0;
            z-index: 99;
            bottom: 0;
            margin:1rem;
            padding: 1rem;
            font-weight: bold;
            background-color: var(--primary-color);
            font-size: 1.5rem;
            color: white;
            border-radius: 5rem 10rem 5rem 10rem;

        }



        .cat-menu-button-open:hover{
            background-color: var(--secondary-color);
        }

        .cat-menu-button-open:active{
            background-color: var(--danger-color);
        }

        .product-list-content{
            .product-list-header{
                .product-list-filter{
                    margin-top: .5rem;
                    margin-right: 10px;
                    margin-left: 10px;
                }
            }

            .product-list{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: .1rem;
            }
        }
    }

    .product-detail-content{
        margin-right: .5rem;
        margin-left: .5rem;
        display: block;

        .product-image{
            width: 100%;
        }
        .product-detail{
            width: 100%;
        }
    }

    .breadcrumb{
        margin-right: .5rem;
        margin-left: .5rem;
    }

}

.custom-modal .ant-modal-content{
    background-color: transparent;  // Arka planı transparan yapar
    padding: 0;
}

.custom-card .ant-card-body{
}

.ant-form-item{
    margin-bottom: 0;
}